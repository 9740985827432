import React, {useState} from "react";
import {Col, Drawer, Row} from "antd";
import {TFunction, withTranslation} from "react-i18next";
import {PngIcon} from "../../common/PngIcon";
import {Button} from "../../common/Button";
import {
    Burger,
    CloseOutline,
    CustomNavLinkSmall,
    HeaderSection,
    Label,
    LogoContainer,
    Menu,
    NotHidden,
    Outline,
    primary,
    secondary,
    Span
} from "./styles";
import {SvgIcon} from "../../common/SvgIcon";

const navItems: { path: string; label: string }[] = [
    {
        path: "/",
        label: "Accueil"
    },
    {
        path: "about",
        label: "À propos de nous"
    },
    {
        path: "faq",
        label: "FAQ"
    },
    {
        path: "services",
        label: "Nos Services"
    }
]

const Header = ({t}: { t: TFunction }) => {
    const [visible, setVisibility] = useState(false);
    const [active, setActive] = useState("/");

    const toggleButton = () => {
        setVisibility(!visible);
    };

    const handleClose = () => setVisibility(false)

    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
            setVisibility(false);
        };
        return (
            <>

                {navItems.map((e, i) =>
                    <CustomNavLinkSmall key={i}
                                        to={e.path}
                                        onClick={() => {
                                            setActive(e.path);
                                            setVisibility(false);
                                        }}>
                        <Span className={active === e.path ? "active" : ""}>{t(e.label)}</Span>
                    </CustomNavLinkSmall>)}

                {/*<CustomNavLinkSmall to="temoignages">*/}
                {/*    <Span>{t("Témoignages")}</Span>*/}
                {/*</CustomNavLinkSmall>*/}
                <CustomNavLinkSmall
                    style={{width: "180px"}}
                    to="contact"
                    onClick={() => setActive("contact")}
                >
                    <Span>
                        <Button color={primary} hoverColor={secondary}>{t("Contact")}</Button>
                    </Span>
                </CustomNavLinkSmall>
            </>
        );
    };

    return (
        <HeaderSection>
            <Row justify="space-between">
                <LogoContainer to="/" aria-label="homepage">
                    <PngIcon src="logo_v1.png" width="120px" height="120px"/>
                </LogoContainer>
                <NotHidden>
                    <MenuItem/>
                </NotHidden>
                <Burger onClick={toggleButton}>
                    <Outline/>
                </Burger>
            </Row>
            <Drawer closable={false} open={visible} onClose={handleClose}>
                <Col style={{marginBottom: "2.5rem"}}>
                    <Label onClick={toggleButton}>
                        <Col span={12}>
                            <Menu>Menu</Menu>
                        </Col>
                        <Col span={12}>
                            <CloseOutline/>
                        </Col>
                    </Label>
                </Col>
                <MenuItem/>
            </Drawer>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
