import {Col, Row} from "antd";
import {TFunction, withTranslation} from "react-i18next";
import {SvgIcon} from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {Empty, Extra, FooterSection, Language, Large, LogoContainer, NavLink, Para, Title,} from "./styles";
import {PngIcon} from "../../common/PngIcon";

interface SocialLinkProps {
    href: string;
    src: string;
}

const Footer = ({t}: { t: TFunction }) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    const SocialLink = ({href, src}: SocialLinkProps) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px"/>
            </a>
        );
    };

    return (
        <>
            <FooterSection>
                <Container>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Language>{t("Liens rapides")}</Language>
                            <Large to="/">{t("Accueil")}</Large>
                            <Large to="/services">{t("Services")}</Large>
                            <Large to="/faq">{t("FAQ")}</Large>
                            <Large to="/contact">{t("Contact")}</Large>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title>{t("Réseaux sociaux")}</Title>
                            <Large to="https://facebook.com">{t("Facebook")}</Large>
                            <Large to="https://linkedin.com">{t("LinkedIn")}</Large>
                            <Large to="https://instagram.com">{t("Instagram")}</Large>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <Empty/>
                            <Language>{t("Mentions légales")}</Language>
                            <Para>
                                <a href="/mentions.pdf" target="_blank" rel="noopener noreferrer">
                                    {t("Mentions légales et politique de confidentialité")}
                                </a>
                            </Para>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <Extra>
                <Container border={true}>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{paddingTop: "1rem"}}
                    >
                        <NavLink to="/">
                            <LogoContainer>
                                <PngIcon src="logo_v1.png" width="110px" height="120px"/>

                            </LogoContainer>
                        </NavLink>
                        <Para>© 2024 BARAKA ÉNERGIES</Para>
                    </Row>
                </Container>
            </Extra>
        </>
    );
};

export default withTranslation()(Footer);
