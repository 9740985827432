import {StyledContainer, StyledContainerFull} from "./styles";
import {ContainerProps} from "../types";

const Container = ({border, children, full}: ContainerProps) => {
    return full ? <StyledContainerFull border={border}>{children}</StyledContainerFull> :
        <StyledContainer border={border}>{children}</StyledContainer>


}

export default Container;
