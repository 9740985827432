const routes = [
    {
        path: ["/", "/home"],
        exact: true,
        component: "Home",
    },

    {
        path: ["/about"],
        exact: true,
        component: "About",
    },
    {
        path: ["/faq"],
        exact: true,
        component: "FAQ",
    },
    {
        path: ["/contact"],
        exact: true,
        component: "Contact",
    },
    {
        path: ["/services"],
        exact: true,
        component: "Services",
    },
];

export default routes;
